import { apiClient } from '../../api';
import { ApiControllers } from '../../api/config'

const getWallets = (id,type) => {
    return apiClient.get(ApiControllers.swap + `SwapCoins`+`/${id}/${type}`);
}

const getSelectedWallets=(id,fromWalletCode,towalletCode)=>{
    return apiClient.get(ApiControllers.swap + `SupportedCoins`+`/${id}/${fromWalletCode}/${towalletCode}`);
}

const getFiatConvertedValue=(fromCurrency,toCurrency,amount)=>{
    return apiClient.get(ApiControllers.swap + `FiatToFiatConverter`+`/${fromCurrency}/${toCurrency}/${amount}`);
}

const getCryptoConvertedValue=(fromCurrency,toCurrency,amount)=>{
    return apiClient.get(ApiControllers.swap + `CryptoToCryptoConverter`+`/${fromCurrency}/${toCurrency}/${amount}`);
}
const getPreviewSwapDetails=(fromCurrency,toCurrency,amount)=>{
    return apiClient.get(ApiControllers.swap + `SwapFiat/preview`+`/${fromCurrency}/${toCurrency}/${amount}`);
}
const getPreviewCryptoSwapDetails=(fromCurrency,toCurrency,amount)=>{
    return apiClient.get(ApiControllers.swap + `SwapCrypto/preview`+`/${fromCurrency}/${toCurrency}/${amount}`);
}
const saveCryptoSwapDetails = (obj) => {
    return apiClient.post(ApiControllers.swap + 'SwapCrypto', obj);
}
const saveFiatSwapDetails = (obj) => {
    return apiClient.post(ApiControllers.swap + 'SwapFiat', obj);
}
export { getWallets,getSelectedWallets,getFiatConvertedValue,getCryptoConvertedValue,getPreviewSwapDetails,saveCryptoSwapDetails,saveFiatSwapDetails,getPreviewCryptoSwapDetails }