import React, { Component } from 'react';
import { Drawer, Typography,Image } from 'antd';
import { processSteps as config } from './config';
import { setStep, setTab, setHeaderTab } from '../../reducers/swapfiatcryptoReducer';
import { connect } from 'react-redux';
import Translate from 'react-translate-component';
import SwapDetails from './swapDetails'
import { getFeaturePermissionsByKeyName } from '../shared/permissions/permissionService'
import PreviewSwap from './previewSwap';
import SelectCoin from './selectCoin';
import Success from './success';
 
const { Paragraph } = Typography
class SwapFiatCrypto extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount(){
        getFeaturePermissionsByKeyName(`Swap`)
    }
    closeSwapDrawer = () => {
        this.props.dispatch(setTab(1));
        this.props.dispatch(setHeaderTab(""))
        if (this.props.onClose) {
            this.props.onClose();
        }
    }
 
    renderContent = () => {
        const { isTabKey } = this.props;
        const stepcodes = {
            selectCrypto: <SelectCoin isTab={isTabKey} />,
            swappingCoins: <SwapDetails isTab={isTabKey}/>,
            previewSwap: <PreviewSwap isTab={isTabKey} />,
            success:<Success isTab={isTabKey}/>
        };
 
        return stepcodes[config[this.props.swapfiatcrypto.stepcode]]

    };
    renderTitle = () => {
        const titles = {
            selectCrypto: <span />,
            swappingCoins: <span onClick={() => this.props.dispatch(setStep("step1"))} className="icon md lftarw-white c-pointer" />,
            previewSwap:<span onClick={() => this.props.dispatch(setStep("step2"))} className="icon md lftarw-white c-pointer" />,
            success:<span onClick={() => this.props.dispatch(setStep("step1"))} className="icon md lftarw-white c-pointer" />,

        }
        return titles[config[this.props.swapfiatcrypto.stepcode]]
    };
    renderIcon = () => {
        const stepcodes = {
            selectCrypto: <span onClick={this.closeSwapDrawer} className="icon md close-white c-pointer" />,
            swappingCoins: <span onClick={this.closeSwapDrawer} className="icon md close-white c-pointer" />,
            previewSwap:<span onClick={this.closeSwapDrawer} className="icon md close-white c-pointer" />,
            success:<span onClick={this.closeSwapDrawer} className="icon md close-white c-pointer" />,

        }
        return stepcodes[config[this.props?.swapfiatcrypto?.stepcode]]
    };
   
    render() {
        return (
        <Drawer
            title={[<div className="side-drawer-header">
                {this.renderTitle()}
                {this.renderIcon()}</div>]}
            placement="right"
            closable={true}
            visible={this.props.showDrawer}
            closeIcon={null}
            className="side-drawer"
            destroyOnClose={true}
        >

            <>
            {this.props.swapfiatcrypto.stepcode == "step1" &&
                 <div className="text-center">
                   
                    <Translate
                with={{ value : this.props?.isTabKey ? 'Crypto' : 'Fiat' }}
                 className="drawer-maintitle buy-sellprocess"
                 content={this.props.swapfiatcrypto.stepTitles[config[this.props.swapfiatcrypto.stepcode]]}
                 component={Paragraph} />
                </div>
               
                }
                 {this.props.swapfiatcrypto.stepcode !== "step1" &&
                 <div className="text-center sell-title-styels">
                    {(this.props.swapfiatcrypto?.setSelectedCoin?.impagePath|| this?.props?.swapfiatcrypto?.setSelectedDashboardCoin?.impagePath || this.props.swapfiatcrypto?.setSelectedToCoin?.impagePath)&&<>
                 {!["step1","step4"].includes(this.props.swapfiatcrypto.stepcode) &&
                <Image preview={false} src={ this.props.swapfiatcrypto?.setSelectedCoin?.impagePath||this?.props?.swapfiatcrypto?.setSelectedDashboardCoin?.impagePath || this.props.swapfiatcrypto?.setSelectedToCoin?.impagePath} />
                }</>}

                     <Translate
                with={{ coin: this.props.swapfiatcrypto?.setSelectedCoin?.walletCode ||this.props?.swapfiatcrypto?.setSelectedDashboardCoin?.coin ||  this.props?.swapfiatcrypto?.setSelectedToCoin?.walletCode}}
                 className="drawer-maintitle buy-sellprocess" content={this.props.swapfiatcrypto.stepTitles[config[this.props.swapfiatcrypto.stepcode]]} component={Paragraph} />
                </div>
            
                }
 
           </>
            {this.renderContent()}
        </Drawer>);
    }
}
 
  
const connectStateToProps = ({ swapfiatcrypto, buyInfo, userConfig }) => {
    return { swapfiatcrypto, buyInfo, userProfileInfo: userConfig?.userProfileInfo }
}
 
export default connect(connectStateToProps)(SwapFiatCrypto);
 
 

