export const sendreceiveSteps = {
  step1: 'depositecrypto',
  withdraw_crypto_selected: 'withdraw',
  step3: "scanner",
  step4: 'withdrawaddress',
  step5: "withdrawsummary",
  step6: "verifyidentity",
  step7: "withdrawscan",
  step8: "selectAddress",
  step9: "selectCrypto",
  step10: "sendMoney",
  withdraw_crypto_liveness: "withdraw_crypto_liveness",
  withdraw_crpto_summary:"withdraw_crpto_summary",
  withdraw_crpto_success:"withdraw_crpto_success",
  withdraw_crpto_Delcaration:"withdraw_crpto_Delcaration"
}