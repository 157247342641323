import React, { Component } from "react";
import {
  Layout,
  Menu,
  Typography,
  Dropdown,
  Drawer,
  Button, Popover
} from "antd";
import { Link, withRouter } from "react-router-dom";
import logoWhite from "../assets/images/SuisseBase.png";
import logoColor from "../assets/images/logo-color.png";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import en from "../lang/en";
import ch from "../lang/ch";
import my from "../lang/my";
import Changepassword from "../components/changepassword";
import {
  updateCoinDetails,
  updateReceiveCoinDetails,
  updateSwapdata,
  clearSwapData,
} from "../reducers/swapReducer";
import { connect, useDispatch } from "react-redux";
import DefaultUser from "../assets/images/defaultuser.jpg";
import { setHeaderTab } from "../reducers/buysellReducer";
import { getScreenName } from "../reducers/feturesReducer";
import { readNotification as readNotifications } from "../notifications/api";
import { setNotificationCount } from "../reducers/dashboardReducer";
import { getmemeberInfo,clearUserInfo } from "../reducers/configReduser";
import HeaderPermissionMenu from '../components/shared/permissions/header.menu';
import { handleHeaderProfileMenuClick } from "../utils/pubsub";
import Notifications from "../notifications";
import { checkCustomerState } from "../utils/service";
import TheamSwitch from "../components/shared/permissions/theamSwitch"
import { useAuth0 } from "@auth0/auth0-react";
import { userLogout } from "../reducers/authReducer";
counterpart.registerTranslations("en", en);
counterpart.registerTranslations("ch", ch);
counterpart.registerTranslations("my", my);
const { Paragraph, Text } = Typography;

const LogoutApp = () => {
  const { logout } = useAuth0()
  const dispatch = useDispatch()
  const clearEvents = () => {
    dispatch(clearUserInfo());
    dispatch(userLogout());
    window.$zoho?.salesiq?.chat.complete();
    window.$zoho?.salesiq?.reset();
    logout();
  }
  return (<li onClick={() => { clearEvents() }}>
    <Link className="text-left">
      <span>
        <Translate
          content="logout"
          className="text-white"
          component={Text}
        />
      </span>
    </Link>
  </li>)
}

class Header extends Component {
  componentDidMount() {
    counterpart.setLocale(
      this.props?.userConfig ? this.props?.userConfig?.language : "en"
    );
  }
  componentWillUnmount() {
    this.props.dispatch(setHeaderTab(""));
  }
  constructor(props) {
    super(props);
    this.state = {
      lang: "en",
      buyToggle: "Buy",
      depositToggle: "From Crypto",
      payDrawer: false,
      payCardsDrawer: false,
      cardsDetails: false,
      billingAddress: false,
      initLoading: true,
      collapsed: true,
      showNotificationsDrawer: false
    };
    this.userProfile = this.userProfile.bind(this);
  }
  userProfile() {
    this.props.history.push("/userprofile");
    if (this.props.oidc.user?.profile?.sub) {
      this.props.getmemeberInfoa(this.props.oidc.user?.profile.sub);
    }
  }
  enableDisable2fa = (status) => {
    var url = "";
    if (status) {
      url =
        process.env.REACT_APP_AUTHORITY +
        "/account/login?returnUrl=/manage/EnableAuthenticator";
    } else {
      url =
        process.env.REACT_APP_AUTHORITY +
        "/account/login?returnUrl=/manage/Disable2faWarning";
    }
    window.open(url);
  };
  readNotification() {
    readNotifications().then(() => {
      this.props.dispatch(setNotificationCount(0));
    });
  }
  routeToHome = () => {
    this.props.dispatch(getScreenName({ getScreen: "dashboard" }))
    this.routeToCockpit();
  };
  routeToCockpit = (val) => {
    this.props.dispatch(setHeaderTab(''));
    if (!this.props.userConfig?.isEmailVerified) { 
      this.props.history.push("/emailVerification");
    } else if (!this.props.userConfig?.isCustomerUpdated) {
      this.props.history.push("/auth0");
    }else if (!this.props.userConfig?.isPhoneNumberVerified) {
      this.props.history.push("/phoneVerification");
    } else if (!checkCustomerState(this.props.userConfig)) {
      this.props.history.push("/sumsub");
    }else if (!this.props.userConfig?.isKYC) {
      this.props.history.push("/notkyc");
    } else if(val =="Marketplace"){
      this.props.history.push("/cockpit/marketplace");
      this.props.dispatch(getScreenName({ getScreen: "Marketplace" }))
    } else {
      this.props.history.push("/cockpit");
      this.props.dispatch(getScreenName({ getScreen: "dashboard" }))
    }
    this.setState({ ...this.state, collapsed: true, isShowSider: false })
  }
  showToggle = () => {
    this.setState({ ...this.state, collapsed: !this.state.collapsed, isShowSider: true })
  }
  onMenuItemClick = (menuitem, menuKey) => {
    handleHeaderProfileMenuClick(menuitem, menuKey);
  }
  handleAuditLogClick=()=>{
    this.props.history.push("/auditLogs")
  }
  uploadDoc=()=>{
    window.open(process.env.REACT_APP_UPLOAD_DOC , '_blank')
}
  render() {
    const userProfileMenu = (
      <Menu>
        <div className="profile-dropdown">

          <Translate
            content={`${this.props.userConfig?.isBusiness === true ? "business_account" : "manage_account"}`}
            component={Button}
            size="medium"
            block
            className="profile-btn"
            onClick={() => this.userProfile()}
          />
          <ul className="drpdwn-list">
            <li
              onClick={() => this.onMenuItemClick("transactions", { key: "transactions", path: "/transactions" })}
            >
              <Link>
                <Translate
                  content="transactions_history"
                  component={Text}
                  className="text-white"
                />
                <span className="icon md rarrow-white" />
              </Link>
            </li>
            <li
            >
              <Popover className="menulist-items-style" placement="left" content={<><div onClick={() => window.open("https://pyrros.instance.kyc-chain.com/#/auth/signup/6120197cdc204d9ddb882e4d")}>
                <Link>
                  <Translate
                    content="personal_account"
                    component={Text}
                    className="text-white" key="1"
                  />
                </Link><span className="icon c-pointer md rarrow-white ml-12" /></div>
                <div onClick={() => window.open("https://pyrros.instance.kyc-chain.com/#/auth/signup/611b3ed20414885a6fc64fa7")}>
                  <Link>
                    <Translate
                      content="business_account"
                      component={Text}
                      className="text-white" key="1"
                    />
                  </Link><span className="icon c-pointer md rarrow-white ml-12" /></div></>} >
              </Popover>
            </li>
            <li
            onClick={ this.handleAuditLogClick }
            >
              <Link>
                <Translate
                  content="AuditLogs"
                  component={Text}
                  className="text-white"
                />
                <span className="icon md rarrow-white" />
              </Link>
            </li>
            <li
              onClick={() => this.onMenuItemClick("addressbook", { key: "addressbook", path: "/addressbook" })}
            >
              <Link>
                <Translate
                  content="address_book"
                  component={Text}
                  className="text-white"
                />
                <span className="icon md rarrow-white" />
              </Link>
            </li>
            <li
              onClick={() => this.onMenuItemClick("cases", { key: "cases", path: "/cases" })}
            >
              <Link>
                <Translate
                  content="case"
                  component={Text}
                  className="text-white"
                />
                <span className="icon md rarrow-white" />
              </Link>
            </li>
            <li
              onClick={() => this.uploadDoc()}
            >
              <Link>
                <span className="text-left">
                  <Translate
                    content="upload_documents"
                    component={Text}
                    className="d-block text-white"
                  />
                  <Translate
                    content='compliance'
                    component={Text}
                    className="text-white"
                  />
                </span>
                <span className="icon md rarrow-white" />
              </Link>
            </li>
            <li
              onClick={() => this.props.history.push("/videoTutorials")}
            >
              <Link className="text-left">
                <span>
                  Video Tutorials
                </span>
                <span className="icon md rarrow-white" />

              </Link>

            </li>
            <LogoutApp />
          </ul>
        </div>
      </Menu>
    );
    return (
      <>
        <Layout className="layout">
          <section className="haead-main">
            <div className="tlv-header" id="area">

              <div className="login-user">
                <ul className="header-logo pl-0">
                  <li className="visible-mobile p-relative" onClick={this.showToggle}>
                    {this.state.collapsed ?
                      <span className="icon lg hamburg " /> : <span className="icon md close-white " />}
                  </li>
                  <li className="mobile-logo">
                    {
                      <img
                        src={logoWhite}
                        alt="logo"
                        className="tlv-logo dark c-pointer p-relative ml-12"
                        onClick={this.routeToHome}
                      />
                    }
                    {
                      <img
                        src={logoColor}
                        alt="logo"
                        className="tlv-logo light c-pointer p-relative ml-12"
                        onClick={this.routeToHome}
                      />
                    }
                  </li>
                </ul>
                <Menu
                  theme="light"
                  mode="horizontal"
                  className="header-right mobile-header-right"
                >
                  <Menu.Item
                    key="6"
                    className="notification-conunt mr-8"
                    onClick={() => this.setState({ ...this.state, showNotificationsDrawer: true })}
                  >
                    <span
                      className="icon md bell ml-4 p-relative"
                      onClick={() => this.readNotification()}
                    >
                      {this.props.dashboard?.notificationCount != null &&
                        this.props.dashboard?.notificationCount !== 0 && (
                          <span>{this.props.dashboard?.notificationCount}</span>
                        )}
                    </span>
                  </Menu.Item>
                  <Menu.Item key="15">

                    <TheamSwitch theamFlag={this.state.theamFalge} />

                  </Menu.Item>
                  <Dropdown
                    trigger={["click"]}
                    overlay={userProfileMenu}
                    placement="topRight"
                    arrow
                    overlayClassName="secureDropdown"
                    getPopupContainer={() => document.getElementById("area")}
                  >
                    <Menu.Item key="7">
                      {this.props.userConfig?.imageURL != null && (
                        <img
                          src={
                            this.props.userConfig?.imageURL
                              ? this.props.userConfig?.imageURL
                              : DefaultUser
                          }
                          className="user-profile "
                          alt={"image"}
                        />
                      )}
                      {this.props.userConfig?.imageURL === null && (
                        <img
                          src={
                            this.props.userConfig?.imageURL
                              ? this.props.userConfig?.imageURL
                              : DefaultUser
                          }
                          className="user-profile"
                          alt={"image"}
                        />
                      )}

                    </Menu.Item>
                  </Dropdown>
                </Menu>
              </div>
              <HeaderPermissionMenu collapsed={this.state.collapsed} isShowSider={this.state.isShowSider} routeToCockpit={this.routeToCockpit} />

            </div>
          </section>
        </Layout>
        <Drawer
          title={[
            <div className="side-drawer-header">
              <span
                onClick={() =>
                  this.setState({ ...this.state, showChangePassword: false })
                }
                className="icon md close-white c-pointer"
              />
              <div className="text-center">
                <Translate
                  className="drawer-maintitle"
                  content="change_pass_word"
                  component={Paragraph}
                />
              </div>
            </div>
          ]}
          placement="right"
          closable={true}
          visible={this.state.showChangePassword}
          closeIcon={null}
          onClose={() =>
            this.setState({ ...this.state, showChangePassword: false })
          }
          className="side-drawer"
        >
          <Changepassword
            onSubmit={() => {
              this.setState({ ...this.state, showChangePassword: false });
            }}
          />
        </Drawer>
        {this.state.showNotificationsDrawer && (
          <Notifications
            showDrawer={this.state.showNotificationsDrawer}
            onClose={() => this.setState({ ...this.state, showNotificationsDrawer: false })}
          />
        )}
      </>
    );
  }
}

const connectStateToProps = ({
  swapStore,
  userConfig,
  oidc,
  dashboard,
  buySell
}) => {
  return {
    swapStore,
    userConfig: userConfig.userProfileInfo,
    twoFA: userConfig.twoFA,
    dashboard,
    buySell,
    oidc
  };
};
const connectDispatchToProps = (dispatch) => {
  return {
    fromObjSwap: (obj) => {
      dispatch(updateCoinDetails(obj));
    },
    receiveObjSwap: (obj) => {
      dispatch(updateReceiveCoinDetails(obj));
    },
    updateSwapdataobj: (obj) => {
      dispatch(updateSwapdata(obj));
    },
    clearSwapfullData: (customerid) => {
      dispatch(clearSwapData(customerid));
    },
    getmemeberInfoa: (useremail) => {
      dispatch(getmemeberInfo(useremail));
    },
    dispatch
  };
};

export default connect(
  connectStateToProps,
  connectDispatchToProps
)(withRouter(Header));
