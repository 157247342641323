
import React from "react";
import AddressCommonCom from "./addressCommonCom";

const AddFiatAddressBook = (props) => {
 
  return (
    <>
      <AddressCommonCom  data={"Fiat"}/>
    </>
  );
};


export default (AddFiatAddressBook);