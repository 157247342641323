export const ApiControllers = {
    withdraw: "Withdraw/",
    transaction: "Transactions/",
    buySell: "Buysell/",
    master: "Master/",
    member: "Member/",
    swap: "Swap/",
    depositWithdraw: "DepositeWithdraw/",
    addressbook: "addressbook/",
    deposit: "Deposit/",
    admin: "Admin/",
    buy: "Buy/",
    sell: "Sell/",
    customers: "Customer/",
    markets: "Markets/",
    wallets: "Wallets/",
    notifications: "Notifications/",
    documents: "Documents/",
    dashboard: "Dashboard/",
    massPayment: "MassPayment/",
    common: "Common/",
    earn: "Earn/",
    partner:"Partner/",
    security:"Security/",
    bank:"Bank/",
    commissions:"Commissions/"
}