import { Component } from "react";
import apiCalls from "../../../api/apiCalls";
import { Form, Row, Col, Input,Select,Alert } from "antd";
import { validateContentRule } from "../../../utils/custom.validator";
import { getReasonforTransferDetails } from "../api";
const { TextArea } = Input;
const {Option}=Select;
class DomesticTransfer extends Component {
    state={
        reasonForTransferDataa:[],
        selectedReasonforTransfer:null,
        errorMessage:null,
    }

    componentDidMount(){
        this.getReasonForTransferData()
    }
    validateNumber = (_, validNumberValue) => {
        if (validNumberValue === "." || validNumberValue && !/^[A-Za-z0-9]+$/.test(validNumberValue) || (validNumberValue?.length<6 && validNumberValue !=undefined && validNumberValue !='')) {
            return Promise.reject("Invalid Uk Sort Code");
        }
        return Promise.resolve();
    }
    getReasonForTransferData=async()=>{
        let res = await getReasonforTransferDetails();
        if(res.ok){
            this.setState({...this.state,reasonForTransferDataa:res.data,errorMessage:null});
        }else{
            this.setState({...this.state,errorMessage: apiCalls.isErrorDispaly(res)});
        }
    }

    handleReasonTrnsfer=(e)=>{
        this.setState({...this.state,selectedReasonforTransfer:e})
        this.props.form.current.setFieldsValue({transferOthers:null})
    }
    renderABIroutingcodeField = () => (
        this.props.currency != 'GBP' && this.props.currency != 'CHF' && this.props.currency != 'SGD' && <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
                className="fw-300 mb-4 text-white-50 py-4 custom-forminput custom-label"
                name="abaRoutingCode"
                label={"ABA Routing Code"}
                required
                rules={[
                    {
                        required: true,
                        message: apiCalls.convertLocalLang("is_required"),
                    },

                    {
                        validator: (_, value) => {
                            if (
                                value &&
                                !/^[A-Za-z0-9]+$/.test(value)
                            ) {
                                return Promise.reject(
                                    "Invalid ABA Routing Code"
                                );
                            } else {
                                return validateContentRule(_, value);
                            }
                        },
                    }
                ]}
            >
                <Input
                    className="cust-input"
                    placeholder={"ABA Routing Code"}
                    maxLength={50} />

            </Form.Item>
        </Col>
    )
    getAlertMsg=()=>(
        this.state.errorMessage && <Alert type="error" description={this.state.errorMessage} showIcon />
    )
    renderTextareaFields = (textName,rules,textLable,textPlaceHolder) => {
      return  <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
                className="custom-forminput custom-label"
                name={textName}
                required
                rules={[
                    rules && {
                        required: true,
                        whitespace: true,
                        message: apiCalls.convertLocalLang("is_required"),
                    },
                    {
                        validator: validateContentRule,
                    },

                ]}
                label={
                    textLable
                }
            >
                <TextArea
                    placeholder={textPlaceHolder}
                    className="cust-input cust-text-area address-book-cust"
                    autoSize={{ minRows: 1, maxRows: 1 }}
                    maxLength={1000}
                ></TextArea>
            </Form.Item>
        </Col>
    }
    render() {
        const { refreshData } = this.props;
        if(refreshData != this.state.domesticTypeName){
            this.setState({...this.state, domesticTypeName:refreshData, selectedReasonforTransfer:null});
            this.props.form.current?.setFieldsValue({transferOthers:null})
        }
        return <Row >
            {this.getAlertMsg()}
            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                    className="fw-300 mb-4 text-white-50 py-4 custom-forminput custom-label"
                    name="accountNumber"
                    label={"Account Number"}
                    required
                    rules={[
                        {
                            required: true,
                            message: apiCalls.convertLocalLang("is_required"),
                        },
                        {
                            validator: (_, value) => {
                                if (
                                    value &&
                                    !/^[A-Za-z0-9]+$/.test(value)
                                ) {
                                    return Promise.reject(
                                        "Invalid Account Number"
                                    );
                                }else {
                                    return validateContentRule(_, value);
                                }
                            },
                        }
                    ]}
                >
                    <Input
                        className="cust-input"
                        placeholder={"Account Number"}
                        maxLength={50}/>

                </Form.Item>
            </Col>
           {this.renderABIroutingcodeField()}
            {this.props.currency == 'GBP' && <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
                name="ukSortCode"
                label="Uk Sort Code"
                className="custom-label"
                type="number"
                rules={[
                    {
                        required: true,
                        message: "Is required",
                    },
                    {
                        validator: this.validateNumber
                    }
                ]}>
                <Input
                    type="text"
                    className="cust-input value-field cust-addon mt-0"
                    prefix={""}
                    placeholder="Uk Sort Code"
                    allowNegative={false}
                    maxLength={6}
                />
            </Form.Item>
        </Col>}
        {(this.props.currency == 'CHF'|| this.props.currency == 'SGD')&&<Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item
                        className="custom-forminput custom-label"
                        name="swiftRouteBICNumber"
                        label={apiCalls.convertLocalLang(
                            "swifbictcode"
                        )}
                        required
                        rules={[
                            {
                                required: true,
                                message: apiCalls.convertLocalLang("is_required"),
                            },{
                                validator: (_, value) => {
                                    if (
                                        value &&
                                        !/^[A-Za-z0-9]+$/.test(value)
                                    ) {
                                        return Promise.reject(
                                            "Invalid Swift / BIC Code"
                                        );
                                    }else {
                                        return Promise.resolve();
                                    }
                                },
                            }
                        ]}
                    >
                        <Input
                            className="cust-input "
                            placeholder={apiCalls.convertLocalLang(
                                "swifbictcode"
                            )}
                            maxLength={50}
                        />
                    </Form.Item>
                </Col>}

            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                    className="custom-forminput custom-label"
                    name="bankName"
                    label={"Bank Name"}
                    required
                    rules={[
                        {
                            required: true,
                            message: apiCalls.convertLocalLang("is_required"),
                        },
                        {
                            whitespace: true,
                            message: apiCalls.convertLocalLang("is_required"),
                        },
                        {
                            validator: validateContentRule,
                        },
                   
                    ]}
                >
                    <Input
                        className="cust-input"
                        placeholder={"Bank Name"}
                        maxLength={100}/>

                </Form.Item>
            </Col>
            {this.renderTextareaFields("bankAddress1",true,"Bank Address 1","Bank Address 1")}
            {this.renderTextareaFields("bankAddress2",false,"Bank Address 2","Bank Address 2")}
           
            {this.props.type !== "manual" && <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                    className="custom-forminput custom-label"
                    name="reasonOfTransfer"
                    required
                    rules={[
                        {
                            required: true,
                            message: apiCalls.convertLocalLang("is_required"),
                        },
                        {
                            whitespace: true,
                            message: apiCalls.convertLocalLang("is_required"),
                        },
                        {
                            validator: validateContentRule,
                        },
                    ]}
                    label={
                        "Reason For Transfer"
                    }
                >
                    <Select
                        className="cust-input"
                        maxLength={100}
                        placeholder={"Reason For Transfer"}
                        optionFilterProp="children"
                        onChange={(e) => this.handleReasonTrnsfer(e)}
                    >
                        {this.state.reasonForTransferDataa?.map((item, idx) => (
                            <Option key={idx} value={item.name}>
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>}
            {this.state.selectedReasonforTransfer=="Others" && <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                            className=" mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error"
                            name="transferOthers"
                            required
                            rules={[
                                {whitespace: true,
                                message: "Is required",
                                },
                                {
                                required: true,
                                message: "Is required",
                                },
                                {
                                validator: validateContentRule,
                            },
                            ]}
                            >
                            <Input
                                className="cust-input"
                                maxLength={100}
                                placeholder="Please specify:"
                            />
                            </Form.Item>
                      </Col>}
        </Row>
    }
}
export default DomesticTransfer;