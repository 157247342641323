import React, { Component } from 'react';
import { Typography, Image,Button } from 'antd';
import { connect } from "react-redux";
import success from '../../assets/images/success.svg';
import Translate from 'react-translate-component';

class DelcarationForm extends Component {
   
    render() {
        const { Title } = Typography;

        return (
            <>
                <div className="custom-declaraton send-success"> <div className="success-pop text-center declaration-content">
       <Image  preview={false} src={success}  className="confirm-icon" />
       <Title level={2} className="successsubtext cust-heightmbl">Your transaction has been processed successfully</Title>
       
       <Translate content="fiat_transfer_success" className=" cust-cancel-btn" component={Button} size="large" onClick={() => { this.props?.back() }}  />
   </div>
   </div>
            </>
        );
    }
}
const connectStateToProps = ({ sendReceive, userConfig }) => {
    return {
        sendReceive,
        userProfile: userConfig.userProfileInfo,
        trackAuditLogData: userConfig.trackAuditLogData
    };
};
export default connect(connectStateToProps)(DelcarationForm);
