export const processSteps = {
    step1: "buycrypto",
    step2: "selectcrypto",
    step3: "summary",
    step4: "billtype",
    step5: "addcard",
    step6: "depositcrypto",
    step7: 'billingaddress',
    step8: 'addressscanner',
    step9: 'depositfiat',
    step10: 'selectedcrypto',
    step11: 'sellsummary',
    step12: 'wiretransfor',
    success: 'successmsg',
    sellsuccess: 'sellsuccessmsg'
}