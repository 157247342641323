import { getPreviewSwapDetails,getPreviewCryptoSwapDetails } from '../components/swapfiatcrypto.component/api';

const SET_STEP = "setStep";
const CLEAR_STEP = "clearStep";
const CHANGE_STEP = "changeStep";
const SET_TAB = "setTab";
const SET_HEADER_TAB = "setHeaderTab";
const SET_CRYPTO_HEADER_HIDE = "setCryptoHeaderHide";
const SET_SELECTED_CRYPTO_COIN = "setSelectedCryptoCoin";
const SET_SELECTED_COIN = "setSelectedCoin";
const PREVIEW_SWAP_COIN = "previewSwapCoin";
const IS_AMOUNT="isAmount"
const SET_SELECTED_TO_COIN = "setSelectedToCoin";
const SET_SELECTED_DASHBOARD_COIN = "setSelectedDashboardCoin";
const SET_SWAP_COINS = "setSwapCoins";
const SET_SELECTED_COIN_ID = "setSelectedCoinId";


const setStep = (payload) => {
    return {
        type: SET_STEP,
        payload
    }
}
const setTab = (payload) => {
    return {
        type: SET_TAB,
        payload
    }
}
const changeStep = (payload) => {
    return {
        type: CHANGE_STEP,
        payload
    }
}
const clearStep = (payload) => {
    return {
        type: CLEAR_STEP,
        payload
    }
}
const setHeaderTab = (payload) => {
    return {
        type: SET_HEADER_TAB,
        payload
    }
}
const setCryptoHeaderHide = (payload) => {
    return {
        type: SET_CRYPTO_HEADER_HIDE,
        payload
    }
}
const setSelectedCryptoCoin = (payload) => {
    return {
        type: SET_SELECTED_CRYPTO_COIN,
        payload
    }
}
const setSelectedCoin = (payload) => {
    return {
        type: SET_SELECTED_COIN,
        payload
    }
}

const previewSwapCoin=(payload)=>{
    return {
        type: PREVIEW_SWAP_COIN,
        payload
    }
}

const isAmount=(payload)=>{
    return {
        type: IS_AMOUNT,
        payload
    }
}
const setSelectedToCoin=(payload)=>{
    return {
        type: SET_SELECTED_TO_COIN,
        payload
    }
}
const setSelectedDashboardCoin = (payload) => {
    return {
        type: SET_SELECTED_DASHBOARD_COIN,
        payload
    }
}

const setSwapCoins = (payload) => {
    return {
        type: SET_SWAP_COINS,
        payload
    }
}

const setSelectedCoinId = (payload) => {
    return {
        type: SET_SELECTED_COIN_ID,
        payload
    }
}


const fetchPreviewSwapDetails = (fromCurrency, toCurrency, amount,isTab) => async (dispatch) => {
    let response;

    if(isTab){
        response = await getPreviewCryptoSwapDetails(fromCurrency, toCurrency, amount);

    }else{
        response = await getPreviewSwapDetails(fromCurrency, toCurrency, amount);
    }
    
    if (response.ok) {
        dispatch(previewSwapCoin(response.data));
    }
    return response; 
}

let initialState = {
    stepcode: "step1",
    stepTitles: {
        selectCrypto: "selectCrypto",
        swappingCoins: "swap_coin",
        previewSwap: "swap_coin",
        success:null
    },
    stepSubTitles: {
        selectCrypto: null,
        swappingCoins: null,
        previewSwap: null,
        success:null

    },
    tabKey: 1,
    headerTab: "",
    sellHeader: null,
    selectedSellCoin: null,
    setSelectedCoin: null,
    previewSwapCoin: {},
    isAmount:null,
    setSelectedToCoin:{},
    setSelectedDashboardCoin:null,
    setSwapCoins:[],
    setSelectedCoinId:null
}

const swapfiatcryptoReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_STEP:
            return { ...state, stepcode: action.payload };
        case CHANGE_STEP:
            return { ...state, stepcode: action.payload };
        case CLEAR_STEP:
            return { ...state, stepcode: action.payload };
        case SET_TAB:
            return { ...state, tabKey: action.payload };
        case SET_HEADER_TAB:
            return { ...state, headerTab: action.payload };
        case SET_CRYPTO_HEADER_HIDE:
            return { ...state, sellHeader: action.payload };
        case SET_SELECTED_CRYPTO_COIN:
            return { ...state, selectedSellCoin: action.payload };
        case SET_SELECTED_COIN:
            return { ...state, setSelectedCoin: action.payload };
        case PREVIEW_SWAP_COIN:
            return { ...state, previewSwapCoin: action.payload };
        case IS_AMOUNT:
                return { ...state, isAmount: action.payload };
        case SET_SELECTED_TO_COIN:
                return { ...state, setSelectedToCoin: action.payload };
        case SET_SELECTED_DASHBOARD_COIN:
                return { ...state, setSelectedDashboardCoin: action.payload };
        case SET_SWAP_COINS:
                return { ...state, setSwapCoins: action.payload };
        case SET_SELECTED_COIN_ID:
                return { ...state, setSelectedCoinId: action.payload };
        default:
            return state;
    }
}

export default swapfiatcryptoReducer;
export { 
    setStep, clearStep, changeStep, setTab, setHeaderTab, setCryptoHeaderHide, setSelectedCryptoCoin, 
    setSelectedCoin, previewSwapCoin, fetchPreviewSwapDetails,isAmount,setSelectedToCoin,setSelectedDashboardCoin,setSwapCoins,
    setSelectedCoinId
};
