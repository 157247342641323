import React, { Component } from 'react';
import { Typography, Card, List, Empty, Image, Alert, Radio } from 'antd';
import { connect } from 'react-redux';
import { setStep, setTab, fetchPreviewSwapDetails, setSelectedCoin, setSelectedToCoin, setSelectedDashboardCoin } from '../../reducers/swapfiatcryptoReducer';
import SuisseBtn from '../shared/butons';
import LocalCryptoSwapperCmp from '../shared/local.crypto.swap/localCryptoSwapperCmp';
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { getFeaturePermissionsByKeyName } from '../shared/permissions/permissionService';
import { getSelectedWallets, getFiatConvertedValue, getCryptoConvertedValue } from './api';
import apicalls from '../../api/apiCalls';
import Translate from 'react-translate-component';
import Loader from '../../Shared/loader';
import Currency from '../shared/number.formate';

class SwapDetails extends Component {
    myRef = React.createRef();
    swapRef = React.createRef();

    constructor(props) {
        super(props);
        const minValue = this.props?.swapfiatcrypto?.minValue ||this.props?.setSelectedDashboard?.swapMinValue ||  "";
        this.state = {
            swapValues: {
                localValue: minValue,
                swappingValue: minValue,
                isSwaped: false,
                isConvertionLoading: false
            },
            selectedWallet: null,
            errorMsg: null,
            btnLoading: false,
            supportedWalletDetails: [],
            selectedCurrency: {},
            convertedValue: null,
            isAmount: minValue,
            isLoading: false,
            minValue: minValue,
            maxValue: this.props?.setSelectedDashboard?.coinBalance || this.props?.swapfiatcrypto?.amount || 0,
            activeButton: 'min',
            selectedWalletCode: null, 
            btnDisabled: false, 
            toWalletCode: false,
            swappedWalletCode: false, 
            onSwap: false,
            swappedCurrency:false

        };
    }

    componentDidMount() {
        getFeaturePermissionsByKeyName(`Swap_fiat` || `Swap_crypto`);
        this.props.setTabKey();
        this.getSelectedWalletsDetails(this.props?.userProfileInfo?.id, this.props?.setSelectedDashboard?.coin || this.props?.swapfiatcrypto?.walletCode, null);

    }

    getSelectedWalletsDetails = async (id, coin, toCoin) => {
        this.setState({ isLoading: true, btnDisabled: false, toWalletCode: false,swappedCurrency:false });
        const selectedImpage = this?.props?.setSwapCoins?.filter(item => item?.walletCode == coin)
        this?.props?.dispatch(setSelectedCoin({ impagePath: selectedImpage[0]?.impagePath, amount: selectedImpage[0]?.amount, walletCode: coin ,minValue:selectedImpage[0]?.minValue,id:selectedImpage[0]?.id}))
        if (selectedImpage.length == 0 && this?.props?.setSelectedDashboard == null) {
            this?.props?.dispatch(setSelectedCoin({ impagePath: this?.props?.setSelectedToCoin?.impagePath, amount: this?.props?.setSelectedToCoin?.amount, walletCode: coin ,minValue:this.props?.setSelectedToCoin?.minValue,id:this?.props?.setSelectedToCoin?.id}))
        }
        else if (selectedImpage.length == 0 && this?.props?.setSelectedDashboard !== null && this.state.onSwap) {
            this?.props?.dispatch(setSelectedCoin({ impagePath: this?.props?.setSelectedToCoin?.impagePath, amount: this?.props?.setSelectedToCoin?.amount, walletCode: coin ,minValue:this.props?.setSelectedToCoin?.minValue,id:this?.props?.setSelectedToCoin?.id}))
        }
        else if (selectedImpage.length == 0 && this.props.setSelectedDashboard !== null && !this.state.onSwap) {
            this?.props?.dispatch(setSelectedCoin({ impagePath: this?.props?.setSelectedDashboard?.impagePath, amount: this?.props?.setSelectedDashboard?.amount, walletCode: coin,minValue:this.props?.setSelectedDashboard?.swapMinValue,id:this?.props?.setSelectedDashboard?.id }))

        }
        let response = await getSelectedWallets(id, coin, toCoin);
        if (response.ok) {
            const supportedWalletDetails = response.data || [];
            if (toCoin && supportedWalletDetails.filter(item => item.walletCode !== toCoin)) {
                this.setState({ ...this.state, toWalletCode: true })
            } else {
                this.setState({ ...this.state, toWalletCode: false })

            }
            if (supportedWalletDetails.length === 0 && toCoin == null) {

                this.setState({
                    supportedWalletDetails: [],
                    isLoading: false,
                    errorMsg: null,
                    btnDisabled: true, onSwap: false,
                    swapValues: {
                        ...this.state.swapValues,
                        localValue: null,
                        swappingValue: null,
                        isConvertionLoading: false,
                    },

                });
                return;
            }

            if (supportedWalletDetails.length === 0 && toCoin !== null) {
                this.setState({
                    supportedWalletDetails: [],
                    isLoading: false,
                    errorMsg: null,
                    selectedWalletCode: coin,
                    btnDisabled: true, onSwap: false,
                    swapValues: {
                        ...this.state.swapValues,
                        localValue: null,
                        swappingValue: null,
                        isConvertionLoading: false,
                    },

                });
                return;
            }

            this.setState({
                supportedWalletDetails,
                isLoading: false,
                errorMsg: null
            }, () => {
                const initialWalletCode = this.state.supportedWalletDetails[0]?.walletCode;
                const { swapfiatcrypto, setSelectedDashboard } = this.props;
                const { isSwaped } = this.state.swapValues;
                if (isSwaped) {
                    this.setState({ selectedWalletCode: coin });
                    this.handleConvertion(
                        coin,
                        setSelectedDashboard?.coin || swapfiatcrypto?.walletCode,
                        this.state?.isAmount
                    );
                }
                else {
                    if (this.state?.swappedWalletCode) {
                        this.setState({ selectedWalletCode: coin });
                        this.handleConvertion(
                            coin,
                            initialWalletCode,
                            this.state.minValue
                        );
                    }
                    else {

                        this.handleConvertion(
                            setSelectedDashboard?.coin || swapfiatcrypto?.walletCode,
                            initialWalletCode,
                            this.state.minValue
                        );
                    }
                }
                if (initialWalletCode) {
                    this.props.dispatch(setSelectedToCoin(this.state.supportedWalletDetails[0]));
                }
            });
        } else {
            this.setState({
                errorMsg: apicalls.isErrorDispaly(response),
                isLoading: false,
                supportedWalletDetails: [], onSwap: false,
                swapValues: {
                    ...this.state.swapValues,
                    swappingValue: 0
                }
            });
        }
    }

    getSelectedCurrency = (item) => {
        const { isSwaped } = this.state.swapValues;
        this.props.dispatch(setSelectedToCoin(item));
        this.setState({ selectedCurrency: item, toWalletCode: false });

        if (!isSwaped) {
            this.setState({ swappedWalletCode: false,swappedCurrency:false })
            this.handleConvertion(this.state?.selectedWalletCode || this.props?.setSelectedDashboard?.coin || this.props.swapfiatcrypto?.walletCode, item?.walletCode, this.state?.isAmount)
        }
        else {

            this.setState({ swappedWalletCode: true,swappedCurrency:true })
            this.handleConvertion(this.state?.selectedWalletCode, item?.walletCode, this.state?.isAmount)
        }

    }

    onValueChange = (value) => {
        const { isSwaped } = this.state.swapValues;
       
        let _nativeValue = this.state.swapValues.localValue;
        let _swappingValue = this.state.swapValues.swappingValue;
        this.setState({ swapValues: { ...this.state.swapValues, isConvertionLoading: true } });
        if (this.state?.supportedWalletDetails.length === 0) {
            this.setState({
                swapValues: {
                    localValue: _nativeValue,
                    swappingValue: _swappingValue,
                    isSwaped
                },
                isAmount: value, btnDisabled: true,errorMsg:null
            });
            return;
        }

        if (isSwaped) {
            _swappingValue = value;
        } else {
            _nativeValue = value;
        }

        if (value === "") {
            this.setState({
                swapValues: {
                    localValue: "",
                    swappingValue: 0,
                    isSwaped
                },
                isAmount: "",
                activeButton: null,
            });
        } else if (value === "0" || value === 0) {
            this.setState({
                swapValues: {
                    localValue: isSwaped ? "" : "0",
                    swappingValue: 0,
                    isSwaped
                },
                isAmount: "0",
                activeButton: null
            });
        } else {
            this.setState(
                {
                    swapValues: {
                        localValue: _nativeValue,
                        swappingValue: _swappingValue,
                        isSwaped
                    },
                    isAmount: value, btnDisabled: false,
                    activeButton: value === this?.props?.swapfiatcrypto?.minValue ? 'min' : value === this?.props?.swapfiatcrypto?.amount ? 'all' : null
                },
                () => {
                    const amount = isSwaped ? _swappingValue : _nativeValue;

                    if (!isSwaped) {
                        this.handleConvertion(
                            this.props.swapfiatcrypto?.walletCode||this.props?.setSelectedDashboard?.coin ,
                            this.state?.selectedCurrency?.walletCode || this.state.supportedWalletDetails[0]?.walletCode,
                            amount
                        );
                    }
                    else if(isSwaped){
                        this.setState({ swappedWalletCode: true })
                        this.handleConvertion(this.state?.selectedWalletCode, this.state?.selectedCurrency?.walletCode, this.state?.isAmount)
                       return;
                    }
                   

                    if (!this.state?.selectedCurrency?.walletCode) {
                        this.props.dispatch(setSelectedToCoin(this.state.supportedWalletDetails[0]));
                    }
                }
            );
        }
    }


    handleConvertion = async (fromCurrency, toCurrency, amount) => {
        const originalAmount = amount;
        if (amount === "." || amount === "" || amount === 0) {
            amount = 0;
        }
        this.setState({
            swapValues: {
                ...this.state.swapValues,
                isConvertionLoading: true,
                localValue: originalAmount
            }, btnDisabled: false,errorMsg:null
        });

        let response;
        if (this?.props?.isTab) {
            response = await getCryptoConvertedValue(fromCurrency, toCurrency, amount);
        } else {
            response = await getFiatConvertedValue(fromCurrency, toCurrency, amount);
        }

        if (response.ok) {
            if (originalAmount == this?.props?.swapfiatcrypto?.minValue) {
                this.setState({ ...this.state, activeButton: 'min' });
            }

            const { isSwaped, localValue, swappingValue } = this.state.swapValues;
            let _nativeValue = localValue, _swappingValue = swappingValue;
            const { data: value, config: { url } } = response;
            let _obj;

            if (this?.props?.isTab) {
                _obj = url.split("CryptoToCryptoConverter")[1].split("/");
            } else {
                _obj = url.split("FiatToFiatConverter")[1].split("/");
            }

            const _val =  localValue;
            if (_obj[3] == _val || _obj[3] == 0) {
                if (!isSwaped) {
                    _swappingValue = value || 0;
                } else {
                    _nativeValue = value || 0;
                }
                if (!isSwaped) {
                    this.setState({
                        ...this.state,
                        swapValues: {

                            localValue: _nativeValue,
                            swappingValue: response.data || 0,
                            isSwaped,
                            isConvertionLoading: false
                        }
                    });
                } 
                else if (isSwaped && this.state?.swappedWalletCode && (toCurrency == null || fromCurrency==null)) {
                    
                    this.setState({
                        ...this.state,
                        swapValues: {
                            localValue: 0,
                            swappingValue: response.data , 
                            isSwaped,
                            isConvertionLoading: false,btnDisabled:true
                        }
                    });
                    return;
                } 
                else if (isSwaped  && this.state?.swappedWalletCode && toCurrency !== null) {
                    this.setState({
                        ...this.state,
                        swapValues: {
                            localValue: response.data || 0,
                            swappingValue: localValue, 
                            isSwaped,
                            isConvertionLoading: false
                        }
                    });
                    return;
                }
                else {
                    this.setState({
                        ...this.state,
                        swapValues: {

                            localValue: response.data || 0,
                            swappingValue: _nativeValue,
                            isSwaped,
                            isConvertionLoading: false
                        },swappedCurrency:true
                    });
                }

                this.setState({
                    convertedValue: response.data,
                    errorMsg: null
                });
            }
          
          
        } else {
            this.setState({
                swapValues: {
                    ...this.state.swapValues,
                    isConvertionLoading: false
                },
                errorMsg: apicalls.isErrorDispaly(response),
                convertedValue: null
            });
        }
    };

    handlePreview = async () => {
        const { isAmount,swapValues } = this.state;
        let _object = this.props?.swapfiatcrypto;
        this.props?.dispatch(setSelectedCoin({
            ..._object,
            currency: this?.props?.setSelectedToCoin?.walletCode,
            swappingAmount: swapValues?.isSwaped? swapValues?.swappingValue : swapValues?.localValue,
            convertedValue: swapValues?.isSwaped?swapValues?.localValue:swapValues?.swappingValue
        }));

        if (isAmount == null || isAmount === "" || isAmount === "0") {
            this.myRef?.current?.scrollIntoView(0, 0);
            this.setState({ errorMsg: "Amount must be greater than zero.", btnLoading: false });
        } else if (isAmount == '.') {
            this.setState({ errorMsg: "Please enter amount to swap.", btnLoading: false });
        }
        else if (isAmount < this?.props?.swapfiatcrypto?.minValue) {
            this.myRef?.current?.scrollIntoView(0, 0);
            this.setState({ errorMsg: `You have entered an amount below the minimum swap. The minimum amount is ${this?.props?.swapfiatcrypto?.minValue} ${this.props.swapfiatcrypto?.walletCode||this.props?.setSelectedDashboard?.coin }.`, btnLoading: false });
        } else if (isAmount > this?.props?.swapfiatcrypto?.amount) {
            this.myRef?.current?.scrollIntoView(0, 0);
            this.setState({ errorMsg: `Insufficient funds.`, btnLoading: false });
        } else {
            this.setState({ btnLoading: true, errorMsg: null });
            const response = await this.props.fetchPreviewSwapDetails(
                this.state?.selectedWalletCode||this.props.swapfiatcrypto?.walletCode||this.props?.setSelectedDashboard?.coin ,
                this.state?.selectedCurrency?.walletCode || this.state.supportedWalletDetails[0]?.walletCode,
                isAmount, this?.props?.isTab
            );
            if (response.ok) {
                this.setState({ btnLoading: false, errorMsg: null, btnDisabled: false });
                this.props.changeStep("step3");
            } else {
                this.setState({ errorMsg: apicalls.isErrorDispaly(response), btnLoading: false, convertedValue: null });
            }
        }
        if (this.state.errorMsg) {
            this.myRef?.current?.scrollIntoView(0, 0);
        }
    };

    clickMinamnt = (type) => {
        const { minValue, maxValue,swapValues } = this.state;
        if (type === "min") {
            if(!swapValues.isSwaped){
            this.setState({
                swapValues: {
                    localValue:this?.props?.swapfiatcrypto?.minValue|| minValue,
                    swappingValue:this?.props?.swapfiatcrypto?.minValue|| minValue,
                    isSwaped: this.state.swapValues.isSwaped
                },  
                activeButton: 'min', 
                isAmount: this?.props?.swapfiatcrypto?.minValue||minValue
            }, () => {
                this.onValueChange(this?.props?.swapfiatcrypto?.minValue||minValue);
            })}
            else if(swapValues.isSwaped){
                this.setState({
                    swapValues: {
                        localValue: this?.props?.swapfiatcrypto?.minValue,
                        swappingValue: this?.props?.swapfiatcrypto?.minValue,
                        isSwaped: this.state.swapValues.isSwaped
                    },  
                    activeButton: 'min', 
                    isAmount: this?.props?.swapfiatcrypto?.minValue
                }, () => {
                    this.onValueChange(this?.props?.swapfiatcrypto?.minValue);
                })
            }
        } else if (type === "all") {
            if(!swapValues.isSwaped){
            this.setState({
                swapValues: {
                    localValue: this?.props?.swapfiatcrypto?.amount||maxValue,
                    swappingValue: this?.props?.swapfiatcrypto?.amount||maxValue,
                    isSwaped: this.state.swapValues.isSwaped
                },
                activeButton: 'all', 
                isAmount:this?.props?.swapfiatcrypto?.amount|| maxValue
            }, () => {
                this.onValueChange(this?.props?.swapfiatcrypto?.amount||maxValue);
            });
        }
        else if(swapValues.isSwaped){
            this.setState({
                swapValues: {
                    localValue: this?.props?.swapfiatcrypto?.amount,
                    swappingValue: this?.props?.swapfiatcrypto?.amount,
                    isSwaped: this.state.swapValues.isSwaped
                },  
                activeButton: 'min',
                isAmount: this?.props?.swapfiatcrypto?.amount
            }, () => {
                this.onValueChange(this?.props?.swapfiatcrypto?.amount);
            })
        }
    }

    };


    render() {
        const { Paragraph } = Typography;
        const { localValue, swappingValue, isSwaped, isConvertionLoading } = this.state.swapValues;
        const currencySymbols = {
            USD: '$',
            EUR: '€',
            GBP: '£',
            CHF: '₣',
            SGD: 'S$',

        };

        return (
            <div id="divScroll" ref={this.myRef}>

                {this.state.errorMsg !== null && (
                    <Alert
                        closable={false}
                        type="error"
                        description={this.state.errorMsg}
                        onClose={() => this.setState({ errorMsg: null })}
                        showIcon
                    />
                )}
                <div className="selectcrypto-container">
                    {this.state.isLoading && <Loader />}
                    {!this.state.isLoading &&
                        <Card className="crypto-card select" bordered={false}>
                            <div>
                                <LocalCryptoSwapperCmp
                                    localAmt={localValue}
                                    cryptoAmt={swappingValue}

                                    localCurrency={
                                        this.state.supportedWalletDetails.length === 0 &&
                                            this.state.onSwap
                                            ? (null)
                                            : (
                                                this.state.swapValues?.isSwaped && this.state?.toWalletCode
                                                    ? null
                                                    : (
                                                        this.state?.swappedWalletCode
                                                            ? this.state?.selectedCurrency?.walletCode
                                                            : (this.state?.selectedWalletCode||this.props.setSelectedDashboard?.coin || this.props.swapfiatcrypto?.walletCode)
                                                    )
                                            )
                                    }

                                    cryptoCurrency={
                                        !this.state.swapValues.isSwaped && this.state.toWalletCode
                                            ? null
                                            : (
                                                this.state?.swappedWalletCode
                                                    ? this.state?.selectedWalletCode
                                                    : (this.state?.selectedCurrency?.walletCode || this.state?.selectedWalletCode || this.state.supportedWalletDetails[0]?.walletCode)
                                            )
                                    }

                                    onChange={(value) => this.onValueChange(value)}
                                    customerId={this.props.userProfileInfo?.id}
                                    screenName='swap'
                                    isSwaped={isSwaped}
                                    onCurrencySwap={() => {
                                        const newIsSwaped = !this.state.swapValues.isSwaped;
                                        this.setState({ ...this.state, onSwap: true })
                                        this.setState(
                                            { swapValues: { ...this.state.swapValues, isSwaped: newIsSwaped } },
                                            () => {
                                                if (this.state.supportedWalletDetails.length === 0) {

                                                    this.props.dispatch(setSelectedToCoin({}));
                                                    this?.props?.dispatch(setSelectedCoin(null))
                                                    this?.props?.dispatch(setSelectedDashboardCoin(null))
                                                    this.setState({ ...this.state, selectedCurrency: {}, selectedWalletCode: null })
                                                }
                                                else if (this.state?.swappedWalletCode) {
                                                    this.getSelectedWalletsDetails(this?.props?.userProfileInfo?.id, this.state?.selectedCurrency?.walletCode, this.state?.selectedWalletCode)
                                                }

                                                else {
                                                    this.getSelectedWalletsDetails(
                                                        this.props?.userProfileInfo?.id,
                                                        newIsSwaped
                                                            ? this.state?.selectedCurrency?.walletCode || this.state?.selectedWalletCode || this.state.supportedWalletDetails[0]?.walletCode
                                                            : this.props?.setSelectedDashboard?.coin || this.props?.swapfiatcrypto?.walletCode,
                                                        newIsSwaped
                                                            ? this.props?.setSelectedDashboard?.coin || this.props?.swapfiatcrypto?.walletCode
                                                            : this.state?.selectedWalletCode || this.state?.selectedCurrency?.walletCode || this.state.supportedWalletDetails[0]?.walletCode
                                                    );
                                                }
                                            }
                                        );
                                    }}
                                    isConvertionLoad={isConvertionLoading}
                                    isCrypto={this?.props?.isTab}
                                />

                            </div>
                            <div className="display-items moblie-order">
                                <div class="text-center mr-16 radio-grp">
                                    <Radio.Group
                                        value={this.state.activeButton}
                                        buttonStyle="solid"
                                        className="round-pills sell-radiobtn-style text-left send-minmax"
                                        onChange={({ target: { value } }) => { this.clickMinamnt(value) }}
                                        disabled={this.state?.btnDisabled}
                                    >
                                        <Translate value="min" content="min" component={Radio.Button} />
                                        <Translate value="all" content="all" component={Radio.Button} />
                                    </Radio.Group>

                                </div>

                                <div className='crypto-details crypto-bal'><div className='sellcrypto-style'>Balance:</div> <Currency
                                    defaultValue={this?.props?.swapfiatcrypto?.amount || this?.props?.setSelectedDashboard?.coinBalance || 0}
                                    prefix={""} type={"text"}
                                    suffixText={this.props.swapfiatcrypto?.walletCode}
                                    className="marginL sellbal-style" />
                                </div>
                            </div>

                            <div className="select-currency">
                                <List
                                    itemLayout="horizontal"
                                    dataSource={this.state.supportedWalletDetails}
                                    className="crypto-list auto-scroll wallet-list selection-currency-list conlist-c-pointer"
                                    locale={{
                                        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                                    }}
                                    renderItem={item => (
                                        <List.Item
                                            className={
                                                this.state.swapValues?.isSwaped
                                                    ? (this.state.selectedCurrency?.walletCode === item?.walletCode
                                                        ? 'select'
                                                        : '')
                                                    : ((this.state.selectedCurrency?.walletCode || this.state.supportedWalletDetails[0]?.walletCode) === item?.walletCode
                                                        ? 'select'
                                                        : '')
                                            }

                                            onClick={() => this.getSelectedCurrency(item)}
                                        >
                                            <Link className={(this.state?.selectedCurrency?.walletCode || this.state.supportedWalletDetails[0]?.walletCode) === item?.walletCode ? "c-notallowed" : ""}>
                                                <List.Item.Meta
                                                    avatar={<Image preview={false} src={item.impagePath} />}
                                                    title={<div className="wallet-title">{item.walletCode}</div>}
                                                />
                                                <div className="text-right coin-typo">
                                                    <NumberFormat
                                                        value={item.amount}
                                                        className="drawer-list-font cust-draw-ellipsis"
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={currencySymbols[item.walletCode]}
                                                        renderText={(value, props) => <div {...props}>{value}</div>}
                                                    />
                                                </div>
                                            </Link>
                                        </List.Item>
                                    )}
                                />
                            </div>
                            <div className='buy-paragraph buy-para-wd'>
                                <Translate content="thousandKText" component={Paragraph} className="buy-paragraph " />
                                <Translate content="contact_amount_text" component={Paragraph} className="buy-paragraph" />
                                <div className="buy-usdt-btn">
                                    <SuisseBtn title="PreviewSwap"
                                        disabled={isConvertionLoading || this.state.btnLoading || this.state?.errorMsg !== null || this.state?.btnDisabled}
                                        loading={this.state.btnLoading}
                                        onRefresh={() => this.refresh()}
                                        className="pop-btn"
                                        onClick={this.handlePreview}
                                        icon={<span className="icon md load" />} />
                                </div>
                            </div>
                        </Card>}
                </div>
            </div>
        );
    }
}

const connectStateToProps = ({ userConfig, swapfiatcrypto }) => {
    return {
        userProfileInfo: userConfig?.userProfileInfo, swapfiatcrypto: swapfiatcrypto?.setSelectedCoin,
        setSelectedDashboard: swapfiatcrypto?.setSelectedDashboardCoin, setSelectedToCoin: swapfiatcrypto?.setSelectedToCoin,
        setSwapCoins: swapfiatcrypto?.setSwapCoins
    };
}

const connectDispatchToProps = dispatch => {
    return {
        setTabKey: () => dispatch(setTab(1)),
        changeStep: (stepcode) => {
            dispatch(setStep(stepcode));
        },
        fetchPreviewSwapDetails: (fromCurrency, toCurrency, amount, isTab) => dispatch(fetchPreviewSwapDetails(fromCurrency, toCurrency, amount, isTab)),
        dispatch
    }
}

export default connect(connectStateToProps, connectDispatchToProps)(SwapDetails);
