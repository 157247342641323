import { useEffect,useState } from "react"
import {Drawer} from "antd";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import PixReceive from "./pixReceive"
import {getPixCurrencies} from '../api'
import apiCalls from "../../../api/apiCalls";
const ReceivePix = (props) => {
    const [pixCurrencies, setPixCurrencies] = useState({});
    const [errorMsg,setErrorMsg]=useState(null)


    useEffect(() => {
        loadCurrencies();
    }, []);   
    
	const loadCurrencies = async () => {
        let response = await getPixCurrencies()
        if(response.ok){
            setPixCurrencies(response.data)
        }
        else{
            setErrorMsg(apiCalls.isErrorDispaly(response))
        }
        }
        
    
         return (
            <Drawer
            title={[
                <div className="side-drawer-header" key={""}>
                    <span />
                    <span
                        onClick={() =>props?.onClose()}
                        className="icon md close-white c-pointer"
                    />
                </div>,
            ]}
                placement="right"
                closable={true}
                visible={props.showDrawer}
                closeIcon={null}
                className="side-drawer custom-fait-sidedrawer"
                destroyOnClose={true}
            >
                <PixReceive pixCurrencies={pixCurrencies} errorMsg={errorMsg}/>
            </Drawer>
        );

}

const connectDispatchToProps = dispatch => {
    return {
        dispatch
    }
  }
  
 export default connect(connectDispatchToProps)(withRouter(ReceivePix));

