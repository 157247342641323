import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Empty, Image, List, Alert } from 'antd';
import Search from "antd/lib/input/Search";
import { getWallets } from './api'
import apicalls from "../../api/apiCalls";
import Translate from 'react-translate-component';
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { setStep,setSelectedCoin,setSelectedDashboardCoin,setSwapCoins,setSelectedCoinId } from "../../reducers/swapfiatcryptoReducer"
import Loader from "../../Shared/loader";

const SelectCoin = (props) => {
    const [cryptoWallet, setcryptoWallet] = useState([]);
    const [cryptoWalletsLoading, setcryptoWalletsLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const [searchVal, setSearchVal] = useState("");
    const [selectedWallet, setSelectedWallet] = useState([]);

    useEffect(() => {
        getcryptoWalletDetails()
    }, [props?.isTab]);

    const getcryptoWalletDetails = async () => {
        setcryptoWalletsLoading(true)
        setErrorMsg(null)
        let response = await getWallets(props?.userProfile?.id, props?.isTab ? "Crypto" : "Fiat")
        if (response.ok) {
            setcryptoWallet(response.data)
            props?.dispatch(setSwapCoins(response.data))
            setcryptoWalletsLoading(false)
            setErrorMsg(null)
            setSelectedWallet(response.data);

        }
        else {
            setcryptoWalletsLoading(false)
            setErrorMsg(apicalls.isErrorDispaly(response))
        }

    }
    const getSelectedCurrency = (item) => {
        props?.dispatch(setSelectedCoin(item))
        props?.dispatch(setSelectedCoinId(item?.id))
        props?.dispatch(setSelectedDashboardCoin(null))
        props.changeStep("step2");

    };

    const handleSearch = ({ currentTarget: { value } }) => {
        setSearchVal(value);
        if (value) {
            const filteredSearchList = cryptoWallet.filter(item => item.walletCode.toLowerCase().includes(value.toLowerCase()));
            setSelectedWallet(filteredSearchList);
        } else {
            setSelectedWallet(cryptoWallet);
        }
    };

    const currencySymbols = {
        USD: '$',
        EUR: '€',
        GBP: '£',
        CHF: '₣',
        SGD: 'S$',
       
    };

    return (
        <>
            {errorMsg !== null && (
                <Alert
                    type="error"
                    description={errorMsg}
                    onClose={() => setErrorMsg(null)}
                    showIcon
                />
            )}
            {cryptoWalletsLoading && <Loader/>}
             {!cryptoWalletsLoading && <>
            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                <Search
                    placeholder="Search Currency"
                    value={searchVal}
                    prefix={<span className="icon lg search-angle drawer-search" />}
                    onChange={(value) => handleSearch(value)}
                    size="middle"
                    bordered={false}
                    className="cust-search"
                />
            </Col>

            <List
                itemLayout="horizontal"
                dataSource={selectedWallet}
                className="crypto-list auto-scroll wallet-list"
                loading={cryptoWalletsLoading}
                locale={{
                    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                        <Translate content="No_data" />
                    } />
                }}
                renderItem={item => (
                    <List.Item
                        className="drawer-list-fiat"
                        onClick={() => getSelectedCurrency(item)}
                    >
                        <Link>
                            <List.Item.Meta
                                className="drawer-coin"
                                avatar={<Image preview={false} src={item.impagePath} />}
                                title={<div className="wallet-title">{item.walletCode}</div>}
                            />
                            <div className="text-right coin-typo">
                                <NumberFormat
                                    value={item.amount}
                                    className="drawer-list-font"
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={currencySymbols[item.walletCode]}
                                    renderText={(value, props) => <div {...props}>{value}</div>}
                                />
                            </div>
                        </Link>
                    </List.Item>
                )}
            /></>}
        </>
    );

}
const connectStateToProps = ({ userConfig, menuItems }) => {
    return { userProfile: userConfig.userProfileInfo, menuItems }
}
const connectDispatchToProps = dispatch => {
    return {
        changeStep: (stepcode) => {
            dispatch(setStep(stepcode))
        },
       
        dispatch
    }
}
export default connect(connectStateToProps,connectDispatchToProps)(SelectCoin);