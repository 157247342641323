import { useEffect, useState } from "react";
import FilePreviewer from "react-file-previewer";
import { Modal, Tooltip, Button, Alert, Spin } from "antd";
import apicalls from "../api/apiCalls";

const DocPreview = (props) => {
  const [previewPath, setPreviewPath] = useState(null);
  const [previewfileName, setPreviewfileName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const mimeType = {
    pdf: "application/pdf",
    png: "image/png",
    jpeg: "image/jpeg",
    jpg: "image/jpg",
    xls: "application/xls",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    txt: "text/plain",
    MP4: "video/mp4",
    QuickTime: "video/quicktime",
    mp4: "video/mp4",
    mov: "video/quicktime",
    MOV: "video/quicktime",
    quicktime: "video/quicktime",
  };
  const [mimetypefiles, setMimeTypeFiles] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    docPreview();
  }, []);

  const docPreview = async () => {
    setIsLoading(true);
    const res = await apicalls.getFileURL(props?.upLoadResponse?.id);
    if (res.ok) {
      handleSuccess(res);
    } else {
      setIsLoading(false);
      setErrorMsg(apicalls.isErrorDispaly(res));
    }
  };

  const handleSuccess = (res) => {
    setIsLoading(false);
    const fileName = props?.upLoadResponse?.fileName || props?.upLoadResponse?.name;
    const extensions = fileName.split(".");
    const fileExtension = extensions[extensions.length - 1];
    setMimeTypeFiles(mimeType[fileExtension]?.toLowerCase());
    setPreviewfileName(fileName);
    setPreviewPath(res.data);
  };

  return (
    <>
      {errorMsg !== null && (
        <Alert
          type="error"
          description={errorMsg}
          onClose={() => setErrorMsg(null)}
          showIcon
        />
      )}
      <Modal
        className="documentmodal-width"
        title="Preview"
        width={1000}
        visible={props.previewModal}
        closeIcon={
          <Tooltip title="Close">
            <span
              className="icon md c-pointer close"
              onClick={props.handleCancle}
            />
          </Tooltip>
        }
        footer={
          <>
            <div className="cust-pop-up-btn crypto-pop">
              <Button
                type="primary"
                onClick={props.handleCancle}
                className="cust-cancel-btn cust-cancel-btn pay-cust-btn detail-popbtn paynow-btn-ml"
              >
                Close
              </Button>
              <a
                className={previewPath && "download-btn" || "download-btn c-notallowed"}
                download={previewfileName}
                href={`data:${mimetypefiles};base64,${previewPath}`}
                title="Download pdf document"
              >
                Download
              </a>
            </div>
          </>
        }
      >
        <Spin spinning={isLoading}>
          {mimetypefiles && mimetypefiles.startsWith("video/") && (
            <video controls width="100%" height="100%" preload="auto">
              <source
                src={`data:${mimetypefiles === "video/quicktime" && "video/mp4" || mimetypefiles};base64,${previewPath}`}
                type={mimetypefiles === "video/quicktime" && "video/mp4" || mimetypefiles}
              />
            </video>
          ) || (
            <FilePreviewer
              hideControls={true}
              file={{
                data: previewPath,
                name: previewfileName,
                mimeType: mimetypefiles
              }}
            />
          )}
        </Spin>
      </Modal>
    </>
  );
};

export default DocPreview;
