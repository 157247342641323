export const assets = {
    "btc": 'btc',
    'erc-20': 'eth',
    'trc-20': 'trx'
}

export const addressRegex = {
    'btc': /^(1[a-km-zA-HJ-NP-Z1-9]{25,34}|3[a-km-zA-HJ-NP-Z1-9]{25,34}|bc1[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{39,59}|bc1p[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{58})$/,
    'erc-20': /^0x[a-fA-F0-9]{40}$/,
    'trc-20': /^(T[1-9A-HJ-NP-Za-km-z]{33}|41[a-fA-F0-9]{40})$/
}

const bitcoinAddressFormats = {
    p2pkh: /^1[a-km-zA-HJ-NP-Z1-9]{25,34}$/,  // Legacy
    p2sh: /^3[a-km-zA-HJ-NP-Z1-9]{25,34}$/,   // Pay-to-Script-Hash
    bech32m: /^bc1p[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{58}$/,    // Taproot (Bech32m)
    bech32: /^bc1[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{39,59}$/,  // SegWit
};
const tronAddressFormats = {
    base58: /^T[1-9A-HJ-NP-Za-km-z]{33}$/,  // Base58 (starts with 'T')
    hex: /^41[a-fA-F0-9]{40}$/              // Hexadecimal (starts with '41')
};

const evmAddressFormats = {
    hexadecimal: /^0x[a-fA-F0-9]{40}$/              // EVM-compatible (starts with '0x')
};

export const getaddressFormat = (network, address) => {
    const getExactFormat = (formats) => {
        for (const key in formats) {
            if (formats[key].test(address)) {
                return key;
            }
        }
        return null
    }
    switch (network) {
        case 'ERC-20': return getExactFormat(evmAddressFormats);
        case 'BTC': return getExactFormat(bitcoinAddressFormats);
        case 'TRC-20': return getExactFormat(tronAddressFormats)
        default: return getExactFormat(evmAddressFormats)
    }
}